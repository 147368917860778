<script setup>
import { useViewBuildingDrafts } from "./buildings_business_logic";
import ViewDraftsTable from "@/components/buildings/tables/ViewDraftsTable.vue";
import ViewDraftItem from "./subModules/ViewDraftItem.vue";
import LabelledField from "@/components/utils/LabelledField.vue";
import { computed, ref, onMounted } from "vue";
import { resetSnackbarOnMount, snackbarData } from "@/components/utils/snackbar/logic";
import { useRouter, useRouterFromInstance } from "@/utils/vue_helpers";
import Pagination from "../utils/Pagination.vue";
import LoadingArea from "../utils/LoadingArea.vue";
import { routes } from "@/router/routes";
import Instructions from "./Instructions.vue";

resetSnackbarOnMount();

const { router } = useRouterFromInstance();

const { buildingsData, searchModel_draft } = useViewBuildingDrafts();

const pages = {
  VIEW_DRAFT_DETAILS: 0,
  VIEW_DRAFT_ITEM : 1

};

const draftUnderView = ref(null);

const currentPage = ref(pages.VIEW_DRAFT_DETAILS);

// const buildingUnderView = ref(null);
// const houseUnderViewNotEmpty = computed(() => {
//   return buildingUnderView.value !== null;
// });

function moveToViewAllDrafts(){
  currentPage.value = pages.VIEW_DRAFT_DETAILS;

}


function moveToViewSingleDraftItem(draft){
  draftUnderView.value = draft
  currentPage.value = pages.VIEW_DRAFT_ITEM

}

function onViewDraftItem(item){
  moveToViewSingleDraftItem(item)

}

function continueApplication(draft_item){
  console.log("continueApplication")
  console.log(draft_item)

  console.log("Cont Draft Item")
  console.log(draft_item)

  router.push({
      name: "createBuildingApp", //use name for router push
      params: {
          draft_item: draft_item
      }
  });

}

function testingFunction($event){
  console.log("testingFunction")
  console.log("testingFunction")
  console.log($event)
  //That $event is actually a number, page number
  buildingsData.setNewPage($event)
  onPaginationClicked($event)

}


// function createBuildingApplication() {
//   currentPage.value = pages.VIEW_BUILDINGS_INSTRUCTION_DETAILS;

// }

// function moveToViewMakeBuildingApplication() {
//   currentPage.value = pages.VIEW_MAKE_BUILDING_APPLICATION;

// }

// function moveToViewBuildingPage(building) {
//   buildingUnderView.value = building;
//   currentPage.value = pages.VIEW_HOUSE;
// }

// function moveToViewHouseTable() {
//   currentPage.value = pages.VIEW_BUILDINGS_DETAILS;
// }

// function moveToViewBuildings() {
//   currentPage.value = pages.VIEW_BUILDINGS_DETAILS

// }

// function onViewPayClicked(house) {
//   buildingUnderView.value = house;
//   currentPage.value = pages.VIEW_MAKE_BUILDING_APPLICATION;
// }

// async function onPayHouseClicked({ house, amountToPay }) {
//   generateHouseBill.mutate(
//     {
//       desired_amount: amountToPay,
//       house_stall_id: house.id,
//     },
//     {
//       onSuccess(bill) {
//         snackbarData.text = "House Bill Generated";
//         snackbarData.color = "success";
//         snackbarData.open = true;

//         const paymentRoute = routes.payment.withQuery({
//           billNumbersToPayAsString: bill.bill_number,
//           directPayment: true,
//           billsToPay: [bill],
//         });
//         router.push(paymentRoute);
//       },
//     }
//   );
// }

async function onPaginationClicked(newPage) {
  buildingsData.paginationData.currentPage = newPage;
  
}

onMounted(() => {
  console.log('Inside OnMounted Drafts')
  console.log(buildingsData)

  console.log('CurrentPage Value Drafts')
  console.log(currentPage.value)


})

</script>

<template>
  <v-container fluid class="pa-0">
    <v-tabs-items v-model="currentPage" touchless continuous>
      <v-tab-item :value="pages.VIEW_DRAFT_DETAILS">
        <v-row>
          <v-col cols="12">
            <div class="text-h6 text-center font-weight-bold primary--text">
              My Drafts
            </div>
          </v-col>
        </v-row>
        <v-row justify-md="center" dense>
          <v-col cols="12" md="6">
            <LabelledField label="Search">
              <v-text-field class="background text-field" outlined dense v-model="searchModel_draft" clearable />
            </LabelledField>
          </v-col>
        </v-row>
        <LoadingArea :loading="buildingsData.fetchingData" class="mt-4">

          <v-row>
            <v-col cols="12">
              <ViewDraftsTable :buildings="buildingsData.fetchedData" :loading="buildingsData.fetchingData"
                @viewDraftItem="onViewDraftItem" @continueApplication="continueApplication"/>
            </v-col>
          </v-row>

          <v-row justify="end">
            <v-col cols="auto">
              <Pagination :current-page="buildingsData.paginationData.currentPage"
                :total-number-of-items="buildingsData.paginationData.total"
                :items-per-page="buildingsData.paginationData.perPage"
                @paginationClicked="testingFunction($event)">
              </Pagination>
            </v-col>
          </v-row>
        </LoadingArea>
      </v-tab-item>

      <v-tab-item :value="pages.VIEW_DRAFT_ITEM">
        <v-row>
          <v-col cols="12">
            <ViewDraftItem :draftUnderView="draftUnderView" @goBackClicked="moveToViewAllDrafts" />
          </v-col>
        </v-row>
      </v-tab-item>





    </v-tabs-items>
  </v-container>
</template>
